import styled from 'styled-components'
import { Container } from '../../../components/ui/wrapper'
import { device } from '../../../theme'

export const SectionWrap = styled.section`
  position: relative;
  z-index: 5;
  background-color: ${(props) => props.theme.colors.background};
  margin-bottom: 150px;

  @media ${device.medium} {
    padding: 0;
  }
`

export const FormWrapper = styled(Container)`
  position: relative;
  top: -100px;
  max-width: 970px !important;
  margin: 0 auto;
  padding: 100px 60px 90px;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 5;

  @media ${device.large} {
    top: -30px;
    padding: 40px;
  }

  @media ${device.medium} {
    top: 0;
  }

  @media ${device.small} {
    padding: 30px;
  }

  @media print {
    max-width: 1230px;
    margin: 0 auto;
    padding: 0;
  }

  > * {
    max-width: 680px;
  }

  h2 {
    padding-top: 24px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: auto;
      height: 2px;
      width: 60px;
      background-color: ${(props) => props.theme.colors.theme};
    }
  }

  input {
    min-height: 48px;
    border-color: ${(props) => props.theme.colors.borderDark};
  }

  small {
    display: block;
  }

  .radio {
    display: flex;
    align-items: baseline;

    input {
      min-height: 0;
      margin-right: 8px;
    }

    label {
      margin-right: 30px;
    }
  }

  .checkbox {
    position: relative;

    input {
      position: absolute;
      top: 12px;
      left: 0;
      min-height: 0;
      margin: 0;
    }

    label {
      margin-bottom: 40px;
      padding-bottom: 40px;
      padding-left: 25px;
      border-bottom: 1px solid ${(props) => props.theme.colors.border};
    }
  }

  .checkbox + .checkbox label {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`

export const Disclaimer = styled.div`
  position: relative;
  padding-top: 20px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 320px;
    max-width: 100%;
    border-top: 1px solid ${(props) => props.theme.colors.border};
  }
`
