import React, { useState } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form/dist/index.ie11'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { Row, Col } from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import Text from '../../../components/ui/text'
import Button from '../../../components/ui/button'
import Alert from '../../../components/ui/alert'
import { Input, Error, FormGroup } from '../../../components/ui/form'
import { SectionWrap, FormWrapper, Disclaimer } from './style'

const NewsletterForm = () => {
  const apiUrl =
    'https://prod-196.westeurope.logic.azure.com:443/workflows/c19bfacfb8374e1e8ed833ee104aa46b/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=869kuLowxpXjZGzOx_pSooEbIJOHq_ezhq9eYvVBRk0'

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
  })

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })

    if (ok) {
      form.reset()
    }
  }

  const onSubmit = (data, event) => {
    const form = event.target
    setServerState({ submitting: true })

    axios({
      method: 'post',
      url: apiUrl,
      data: data,
    })
      .then((response) => {
        setServerState({ submitting: false })
        handleServerResponse(true, 'Uw aanmelding is succesvol verzonden', form)
      })
      .catch((response) => {
        console.log(response.response.data.error)
        setServerState({ submitting: false })
        handleServerResponse(
          false,
          'Er is iets misgegaan met het verzenden van uw aanvraag. Probeer het later nogmaals',
          form,
        )
      })
  }

  const visitPrivacyPage = () => {
    window.location = '/privacy-en-cookies'
  }

  return (
    <SectionWrap className="container-xl">
      <FormWrapper>
        <Row>
          <Col md={12}>
            <Heading as="h2" mb="16px">
              Meld je aan voor onze nieuwsbrief
            </Heading>
            <Text mb="50px">Velden met een * zijn verplicht.</Text>

            {serverState.status && (
              <Alert
                variant={serverState.status.ok ? 'success' : 'danger'}
                mb="30px"
              >
                {serverState.status.ok ? (
                  <FaCheck className="icon" role="img" />
                ) : (
                  <FaTimes className="icon" role="img" />
                )}
                {serverState.status.msg}
              </Alert>
            )}

            <form onSubmit={handleSubmit(onSubmit)} autoComplete="on">
              <Row mb="50px">
                <Col sm={6} mb="20px">
                  <FormGroup>
                    <label htmlFor="email">E-mailadres *</label>
                    <Input
                      bgcolor="white"
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="email"
                      placeholder="Voer uw e-mailadres in"
                      aria-label="Voer uw e-mailadres"
                      ref={register({
                        required: 'Email is verplicht',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message:
                            'Het ingevoerde emailadress lijkt niet te kloppen',
                        },
                      })}
                    />
                    <Error htmlFor="email">
                      {errors.email && errors.email.message}
                    </Error>
                  </FormGroup>
                </Col>

                <Col sm={6} mb="20px">
                  <FormGroup>
                    <label htmlFor="firstname">Voornaam</label>
                    <Input
                      bgcolor="white"
                      type="text"
                      name="firstname"
                      id="firstname"
                      placeholder="Voornaam"
                      aria-label="Voornaam"
                      ref={register}
                      autoComplete="given-name"
                    />
                  </FormGroup>
                </Col>

                <Col sm={6} mb="20px">
                  <FormGroup>
                    <label htmlFor="middlename">Tussenvoegsel(s)</label>
                    <Input
                      bgcolor="white"
                      type="text"
                      name="middlename"
                      id="middlename"
                      placeholder="Tussenvoegsel(s)"
                      aria-label="Tussenvoegsels"
                      ref={register}
                      autoComplete="additional-name"
                    />
                  </FormGroup>
                </Col>

                <Col sm={6} mb="20px">
                  <FormGroup>
                    <label htmlFor="lastname">Achternaam</label>
                    <Input
                      bgcolor="white"
                      type="text"
                      name="lastname"
                      id="lastname"
                      placeholder="Achternaam"
                      aria-label="Achternaam"
                      ref={register}
                      autoComplete="family-name"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col mb="50px">
                  <Heading as="h3" mb="16px">
                    Over de nieuwsbrief
                  </Heading>
                  <Text>
                    Regio West-Brabant verstuurt minimaal een keer in de twee
                    maanden de nieuwsupdate #KrachtigeRegio. Laat je gegevens
                    achter in het veld hierboven en vink hieronder
                    #KrachtigeRegio aan.
                  </Text>
                </Col>
              </Row>

              <Row mb="46px">
                <Col>
                  <FormGroup className="checkbox">
                    <input
                      type="checkbox"
                      name="newsletters"
                      value="Krachtige regio"
                      id="newsletter-1"
                      ref={register}
                    />
                    <label htmlFor="newsletter-1">
                      <Heading as="h4" mb="16px">
                        #KrachtigeRegio
                      </Heading>
                      <Text>
                        Op de hoogte blijven van ons laatste nieuws? Meld u dan
                        aan voor onze e-mailnieuwsbrief #KrachtigeRegio. Deze
                        verschijnt ongeveer 6 x per jaar.
                      </Text>
                    </label>
                  </FormGroup>
                </Col>
              </Row>

              <Row mb="70px">
                <Col>
                  <Button type="submit" disabled={serverState.submitting}>
                    Inschrijven
                  </Button>
                </Col>
              </Row>
            </form>

            <Disclaimer>
              <Heading as="h3" mb="16px">
                Privacybeleid Regio West-Brabant
              </Heading>
              <Text>
                Regio West-Brabant hecht aan privacy. Zij draagt zorg voor een
                betrouwbare en zorgvuldige bescherming van de aan haar
                toevertrouwde persoonsgegevens. U geeft ons toestemming voor het
                toesturen van de door u aangegeven nieuwsbrieven. Wij gebruiken
                uw gegevens alleen voor het verzenden van onze nieuwsbrieven.
                Wanneer u toestemming heeft gegeven, kunt u deze te allen tijde
                intrekken door in de nieuwsbrief op uitschrijven te klikken. De
                volledige privacyverklaring van Regio West-Brabant kunt u hier
                raadplegen.
              </Text>
              <Row mb="70px">
                <Col>
                  <Button onClick={visitPrivacyPage}>Privacybeleid</Button>
                </Col>
              </Row>
            </Disclaimer>
          </Col>
        </Row>
      </FormWrapper>
    </SectionWrap>
  )
}

export default NewsletterForm
